import { Toaster } from "sonner"
import { MainAppRoute } from '@/rootRoutes/MainApp';
import { Route, useBlocker, useNavigate, useParams } from '@tanstack/react-router';
import { useContext, useEffect, useState } from 'react';
import { useGetProjectContext, useSubmitAlgoEvaluation } from '../../../openapi/api/endpoints/default/default';
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels"
import CodeEditor from "@/components/Editor/CodeEditor"
import MarkdownEditor from "@/components/Editor/MarkdownEditor"
import {  AlgoEvaluation as AlgoEvalType, AlgoProjectContext } from "openapi/api/model"
import TestCoverageDisplayer from "@/components/Editor/TestCoverageDisplayer"
import { AuthContext } from "@/store/track-context";
import LoadingComponent from "./LoadingComponent";
import { stopVideoProctoring } from "./VideoProctoring/VideoProctoringHooks";



export const BeginAlgoTestPage= ()=>{
  const {templateId,skill} = useParams({from:'/'});
  //const projectID = courseId
  const navigate = useNavigate({from:'/'})
  const [isBlockerActive, setIsBlockerActive] = useState(true);
  const {track}=useContext(AuthContext)
  const [evaluationResult,setEvaluationResult]=useState<AlgoEvalType>()
  const {mutateAsync,isPending}=useSubmitAlgoEvaluation()
  const { data:context } = useGetProjectContext({templateId:templateId??'',skill:skill??''},{
    query:{
      enabled: !!(templateId&&skill)
    }
  })

  const submitEvaluation=async ()=>{
    try{
      if(evaluationResult){
        await mutateAsync({
          data:evaluationResult
        })
      }else{
        const undefinedEvalResult={
          code:"N/A",
          hiringCampaignId:track?.hiringCampaignId??'',
          skill:skill,
          templateId:track?.trackProgression.find((project)=>project.skill===skill)?.templateId??'',
          trackID:track?._id??'',
          unitTestPassRate:0,
          sub:track?.sub,
          _id:track?.trackProgression.find((project)=>project.skill===skill)?.projectID??''
        }
        setEvaluationResult(undefinedEvalResult); // Set the coverage once processed
        await mutateAsync({
          data:undefinedEvalResult
        })
      }
      await stopVideoProctoring()
    }catch(err){
      console.log((err as Error).message)
      throw((err as Error).message)
    }finally{
      setIsBlockerActive(false)
    }
  }

    
  const disableBlocker = () => {
    setIsBlockerActive(false);
  }

  useEffect(() => {
    if (!isBlockerActive) {
      // Navigate to the overview page after blocker is disabled
      navigate({to:'/overview'});
    }
  }, [isBlockerActive, navigate]);

  useBlocker({
    blockerFn:async () => {
      if (!isBlockerActive) return true;
      const confirmLeave=window.confirm('Are you sure you want to leave? This would terminate the evaluation')
      
      if(confirmLeave){
        try{
          await submitEvaluation()
          console.log('Submitted...')
        }catch(err){
          console.log((err as Error).message)
        }
      }
      return confirmLeave

    },
    condition:isBlockerActive
  })

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      // show a confirmation dialog when attempting to close browser
      const message = "Are you sure you want to leave?";
      event.returnValue = message; // Legacy way of showing a confirmation dialog
      return message; // Modern way of showing a confirmation dialog
    };

    const handleUnload = async() => {
      
      try{
        await submitEvaluation()
        navigate({to:'/overview'});
        console.log('Submitted...')
      }catch(err){
        console.log((err as Error).message)
      }
        console.log('Leaving page...')
    };
    //attach event listeners on mount
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("unload", handleUnload);


    return () => {
      //get rid of event listeners on dismount
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("unload", handleUnload);
    }
  
  }, [])


  return(
  <>
    <div className="flex flex-col py-2 px-4 bg-gray-100 h-[100dvh] max-md:w-screen overflow-x-auto">
      <LoadingComponent
        isSubmitting={isPending}
        loadingTitle="Submitting Assignment"
        loadingMessage="Please wait while we process your submission. This may take a few moments."
      />
      <PanelGroup className="flex-auto w-full h-full flex flex-col" autoSaveId="example" direction="horizontal">
        <Panel defaultSizePercentage={50} className="flex flex-col">
          <div className="flex-grow overflow-auto">
            <MarkdownEditor markdown={context?.ProjectProposal as string} timerDone={disableBlocker} />
          </div>
          <div className="mt-4">
            <TestCoverageDisplayer coverage={evaluationResult?.unitTestPassRate??0} onSubmit={submitEvaluation}/>
          </div>
        </Panel>

        <PanelResizeHandle className="rounded m-1 w-2 bg-[#26A69A] hover:opacity-75 hover:w-3 transition-all duration-200 cursor-col-resize" />

        <Panel defaultSizePercentage={50}>
          <CodeEditor skill={skill} context={context as AlgoProjectContext?? {}} setCoverage={setEvaluationResult}/>
        </Panel>
      </PanelGroup>
    </div>
    <Toaster/>
  </>
  )
}


const BeginAlgoTestRoute = new Route({
  getParentRoute: () => MainAppRoute,
  path: "course/$courseId/$skill/algo/$templateId",
  component: BeginAlgoTestPage,
})

export default BeginAlgoTestRoute;