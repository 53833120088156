
import { useQuery } from "@tanstack/react-query"
import { customClient } from "../../../../../openapi/mutator/custom-client"
import { AxiosError } from "axios"

export const useGetIsSEB=()=>{ 
    return useQuery({
        queryKey: ['seb'],
        queryFn: async()=>{
            try{
                await checkSEB()
                return {isSEB:true}
            }catch(err){
                return {isSEB:false, error:`${(err as AxiosError).message}`}
            }
        },
    })
}

export const checkSEB = async() => {
    return customClient({
        url:'/seb/isSEB',
        method:'GET',     
    })
}
